import React from "react";


const Form = (props) => {


  return (
<div class="elfsight-app-a5842045-f0df-48c8-8c86-defeadc5e0f9 p-5"></div>
  );
};

export default Form;


// variable del formulario:
// tname=""
// tlname=""
// temail=""
// tphone=""
// taddress=""
// ttypeservice=""
// tbestway=""
// tcheckphone=""
// tcheckcorreo=""
// tmessage=""
// tbutton=""
// isenglish="true"
